import React from 'react';
import { shape } from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';
import PostIterator from '../components/news-events/iterator';
import NewsEventsBlog from '../components/news-events/card-blog';
import Axios from '../utils/axios';

const propTypes = {
  data: shape({}).isRequired,
};

class NewsAndEventsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: props.data.allStrapiPost.edges,
      showMore: true,
      offset: 10,
    };

    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  async handleLoadMore() {
    const { offset } = this.state;
    const { data: posts } = await Axios.get(`/posts/?_start=${offset}&_limit=10`);
    const showMore = posts.length !== 0;

    this.setState(state => ({
      posts: state.posts.concat(posts.map(node => ({ node }))),
      offset: state.offset + 10,
      showMore,
    }));
  }

  render() {
    const { posts, showMore } = this.state;

    return (
      <Layout title="News and Events">
        <Hero
          backgroundImage="zoo-pharm"
          headline="News & Events"
          tagline=""
        />
        <section className="content-wrapper news-events-blog">
          <Grid justifyContent="center" alignItems="center">
            <GridItem md={8}>
              <PostIterator
                posts={posts}
                render={(post, path, event) => (
                  <NewsEventsBlog
                    key={post.slug}
                    title={post.title}
                    date={post.created_at}
                    text={post.shortDescription}
                    to={path}
                    event={event}
                  />
                )}
              />
            </GridItem>
          </Grid>
          <Grid>
            <GridItem>
              <div className="c-btn-post h-text-center">
                {showMore ? (
                  <button
                    type="button"
                    className="c-button--primary c-btn-post"
                    onClick={this.handleLoadMore}
                  >
                    Load More
                  </button>
                ) : (
                  <p>End of the list</p>
                )}
              </div>
            </GridItem>
          </Grid>
        </section>
      </Layout>
    );
  }
}

NewsAndEventsPage.propTypes = propTypes;

export default NewsAndEventsPage;

export const query = graphql`
  query {
    allStrapiPost (
      limit: 10
      sort: { fields: strapiId, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          type
          shortDescription
          created_at
        }
      }
    }
  }
`;
