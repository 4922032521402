import React from 'react';
import { string, bool } from 'prop-types';
import { Link } from 'gatsby';
import moment from 'moment';

import NewsIcon from '../../images/news-icon.png';
import CalendarIcon from '../../images/calendar-icon.png';

const propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  date: string.isRequired,
  to: string.isRequired,
  event: bool,
};

const defaultProps = {
  event: false,
};

const getIcon = (event) => {
  let icon = NewsIcon;
  if (event) {
    icon = CalendarIcon;
  }
  return (
    <img src={icon} alt="ZooPharm" />
  );
};

const NewsEventsBlog = ({
  title, text, event, date, to,
}) => (
  <div className="news-events-box">
    <Link to={to}>
      <h2>
        {getIcon(event)}
        {title}
      </h2>
    </Link>
    <p className="post-date">
      {`Posted on ${moment(date).format('MMMM Do YYYY')}`}
    </p>
    <p>{text}</p>
  </div>
);

NewsEventsBlog.propTypes = propTypes;
NewsEventsBlog.defaultProps = defaultProps;

export default NewsEventsBlog;
